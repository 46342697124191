import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './pvp.scss';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkePVPBurst: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp-nikke'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>PvP Burst Generation</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_pvp3.jpg"
            alt="PVP"
          />
        </div>
        <div className="page-details">
          <h1>PVP Burst Energy Generation</h1>
          <h2>
            Everything you need to know about Burst Generation in NIKKE PVP.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content with-italics">
        <SectionHeader title="Foreword" />
        <p>
          This guide has been created by <strong>awesome21snake</strong>. If you
          have any feedback about the guide, catch him on our Discord!
        </p>
        <SectionHeader title="Introduction" />
        <p>
          Before going anywhere near charts, numbers and calculations, I will
          try to explain all the terminologies that people use when talking
          about Burst Gen. I would like to thank this{' '}
          <a
            href="https://ngabbs.com/read.php?tid=36406961&rand=5"
            target="_blank"
            rel="noreferrer"
          >
            epic NGA
          </a>{' '}
          pvp guide for their burst gen calculation ideas,{' '}
          <a href="https://nikke.gg/" target="_blank" rel="noreferrer">
            Nikke.gg
          </a>{' '}
          for their basic definitions, along with the amazing PvP specialists in
          our maid team of Nikke Community Server:{' '}
          <strong>cleancascade, Scy_v4x, KosMiu</strong>, who helped to perfect
          PvP burst gen calculation; and <strong>zixk98</strong> for helping
          with the new PvP charts.
        </p>
        <SectionHeader title="Basic Definitions" />
        <h5>Burst energy gauge/bar</h5>
        <p>
          The burst gauge is what needs to be filled in order to get to full
          burst. Burst energy is used to fill the burst gauge. The following
          flowchart shows the stages the burst gauge goes through:
        </p>
        <div className="burst-flow">
          <StaticImage src="../../../images/nikke/pvp/pvp_18.webp" alt="PVP" />
          <p>Empty Burst gauge</p>
          <div className="line"></div>
          <StaticImage src="../../../images/nikke/pvp/pvp_19.webp" alt="PVP" />
          <p>
            Burst gauge after Nikkes hit shots on opponents - generating burst
            energy
          </p>
          <div className="line"></div>
          <StaticImage src="../../../images/nikke/pvp/pvp_20.webp" alt="PVP" />
          <p>Burst gauge when it becomes full (B1, B2 and then B3 stage)</p>
          <div className="line"></div>
          <StaticImage src="../../../images/nikke/pvp/pvp_21.webp" alt="PVP" />
          <p>Full Burst stage which slowly drains</p>
        </div>
        <p>
          When the burst gauge meter fills to full, your burst 1 character will
          start the burst chain and use her burst. One character each will burst
          in order from burst 1 -&gt; burst 2 -&gt; burst 3. In auto mode, which
          is the mode in pvp, units on the left (i.e. units who are in positions
          prior to the other units from left to right) will take priority when
          there are two or more of the same burst type.{' '}
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_22.webp" alt="PVP" />
        <p>
          In the case above, Liter would burst first since she was in position 2
          while Jackal was in position 4. Similarly, Scarlet would burst instead
          of Noir because Scarlet is in position 1 while Noir is in Position 5.{' '}
          <strong>
            Note: There is a delay between appearance and activation of each
            Burst Stage
          </strong>
          .
        </p>
        <p>
          With this, we can now move on to what burst energy is and how it works
          for different Nikkes in pvp.
        </p>
        <h5>Burst energy generation/Burst gen/Burst energy</h5>
        <p>
          <strong>
            Every time a Nikke lands a hit, they generate burst energy
          </strong>
          . It is important to note that this is per hit, so RL hits can
          generate more burst gen naturally since they hit more than one
          opponent in normal situations.{' '}
          <em>Each unit has a specific burst gen</em> per hit value. Some units
          generate more burst energy than others, even if they are of the same
          weapon type. Exact values will be discussed later.
        </p>
        <p>
          <strong>
            Note: Extra hits (Modernia s2) and counter attacks (Scarlet s2 or
            Jackal s1) count as hits, and generate the same burst gen value as
            the Nikke’s base burst gen value.
          </strong>
        </p>
        <p>
          Different weapon types generate more energy per hit. It is important
          to bring enough burst energy generation since faster bursts have an
          easier time winning in PvP.
        </p>
        <Table striped bordered responsive className="table-nikke">
          <thead>
            <tr>
              <th>Weapon Type</th>
              <th>Burst Gauge Generation</th>
              <th>Units</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Rocket Launcher (Clip RL)</strong>
              </td>
              <td>Very high energy generation</td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Shotgun (Clip SG)</strong>
              </td>
              <td>High energy generation</td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="drake" enablePopover />
                  <NikkeCharacter mode="icon" slug="sugar" enablePopover />
                  <NikkeCharacter mode="icon" slug="maiden" enablePopover />
                  <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                  <NikkeCharacter mode="icon" slug="neon" enablePopover />
                  <NikkeCharacter mode="icon" slug="viper" enablePopover />
                  <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  <NikkeCharacter mode="icon" slug="product-23" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Rocket Launcher (Mag RL)</strong>
              </td>
              <td>Good energy generation</td>
              <td>
                All other Rocket Launchers (some anomalies like Pascal or A2 who
                generate High burst gen)
              </td>
            </tr>
            <tr>
              <td>
                <strong>Shotgun (Mag SG)</strong>
              </td>
              <td>Some energy generation</td>
              <td>All other Shotguns</td>
            </tr>
            <tr>
              <td>
                <strong>Sniper Rifle</strong>
              </td>
              <td>Low energy generation</td>
              <td>
                Sniper Rifles (some anomalies like Nihilister or Harran who can
                generate High Burst Gen)
              </td>
            </tr>
            <tr>
              <td>
                <strong>Assault Rifle</strong>
              </td>
              <td>Very Low energy generation</td>
              <td>
                Assault Rifles (some anomalies e.g. Scarlet who generates Good
                burst gen)
              </td>
            </tr>
            <tr>
              <td>
                <strong>Submachine Gun</strong>
              </td>
              <td>Poor energy generation</td>
              <td>
                All Submachine Guns (some anomalies e.g. Ludmilla who generates
                Low burst gen)
              </td>
            </tr>
            <tr>
              <td>
                <strong>Machine Gun</strong>
              </td>
              <td>Poor energy generation</td>
              <td>
                All Machine Guns (some anomalies e.g. Modernia who generates Low
                Burst gen)
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>
          How Burst gen for units is calculated for different weapon types
        </h5>
        <p>This has been explained before in The Basics</p>
        <ul>
          <li>
            <strong>RL</strong> hitting P1/P5 can cause AOE damage to 4 targets
            which are P1+P2+P1 cover+P2 cover, so the burst gen of that
            particular RL is 4× her normal burst gen value; RL hitting P2/P3/P4
            can cause AOE damage to 6 targets. Therefore, it is 6× the normal
            burst gen of the Nikke.
          </li>
          <li>
            RL and SR can enter cover between shots and{' '}
            <strong>can dodge incoming projectiles of opponent RLs</strong>. We
            call this phenomenon{' '}
            <strong>"Rocket whiffing" or “i-framing”</strong>. If this event
            occurs, the fire focus party will lose tremendous amounts of burst
            gen, and this can be the deciding factor of a win or a loss in 95%
            of matchups.
          </li>
          <li>
            <strong>
              Some Nikkes have skills that can give extra burst gen
            </strong>
            , such as Scarlet’s skill 2, Jackal’s skill 1, Harran’s skill 1,
            Modernia’s skill 1. The charging amount that is triggered once is
            the basic energy, Scarlet: 0.45, Jackal: 3.55, Modernia: 0.05,
            Harran: 2.9.
          </li>
          <li>
            Nihilister, A2, and Pascal are special Nikkes in their weapon types:
          </li>
          <ul>
            <li>
              Nihilister has higher burst gen than other SRs due to her skills.
              Second shot onwards, Nihilister gets pierce, damages cover and
              Nikke and that counts as hitting 2 enemies, and then she deals
              extra damage to both cover and Nikke due to her skill 2, hitting 4
              times every shot.
            </li>
            <li>
              A2 is a slower shooting RL than other RLs. She shoots 2 bullets
              for every 3 bullets normal RLs shoot. However due to her base
              splash radius being higher than other RLs, she hits P1+P2+P3+cover
              of all 3, making her burst gen 6× the normal burst gen per shot.
            </li>
            <li>
              Pascal is an automatic RL. His base burst gen value is lower than
              other RLs but she shoots faster than them. Pascal shoots 6 bullets
              for every 3 bullets normal RLs shoot. However, he wastes one of
              his bullets by hitting the ground due to an ongoing bug. Thanks to
              Keripo for publicising this!
            </li>
          </ul>
          <li>
            Dual-wielders like Noah, Soline, Crow and Quency shoot only from one
            gun in PvP, and thus their burst gen is halved.
          </li>
        </ul>
        <p>
          I believe we now should have a decent understanding of what Burst
          energy is, and how it is different for each Nikke. I think it’s time
          to understand how we define the speeds in which teams burst in PvP
          Arena.
        </p>
        <SectionHeader title="Nomenclature for Burst Speeds of Teams" />
        <Alert variant="primary">
          <p>
            <strong>
              This section is extremely important to understand, as Burst Speed
              is extremely crucial to Team-Building in PvP.
            </strong>
          </p>
        </Alert>
        <p>
          Since the burst gen of different Nikkes varies a lot, weapons that can
          generate a large amount of burst gen are often used as measurement
          units to calculate the burst gen of a particular team. These weapons
          are Rocket launchers and Clip shotguns.
        </p>
        <p>
          <strong>Note:</strong> Reaching Full Burst Gauge means reaching the B1
          stage, not reaching full burst.
        </p>
        <ul>
          <li>
            A high-speed team generally refers to a team that can reach full
            burst gauge when the third shot of "1s charge time RL" is hit,
            abbreviated as: <strong>“3RL team”</strong>.
          </li>
          <li>
            A very high-speed team generally refers to a team that can have a
            full burst gauge with 2 shots of "1s charge time RL". The team that
            can reach full burst when the second shot hits, are referred to as:
            <strong> “2 RL team”</strong>.
          </li>
          <li>
            Teams that can get to a full burst gauge when Clip SGs shoot 5 times
            are referred to as: <strong>“5SG or 2.5RL team”</strong>.
          </li>
          <ul>
            <li>
              The term 2.5RL is used since they burst between 2RL and 3RL teams.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Note:</strong> 2 shots of 1s charge time RL does not mean 2
          seconds. Due to delays in RL travel time and RLs going into cover for
          a few frames, it is actually ~2.5 seconds.{' '}
          <strong>Additional Note:</strong> 2RL does not mean that there are 2
          Rocket Launchers in the team. If you think that, please read this page
          again!!
        </p>
        <p>
          Now similarly, a 4RL team will be a team that gets a full burst gauge
          in 4 RL shots. And a 3.5RL team will be a team that can get a full
          burst gauge between 3 and 4RL shots. A 3.5RL team will also be called
          the 7SG team (i.e. a team that reaches full burst gauge in 7 SG
          shots).
        </p>
        <p>
          <strong>Note:</strong> Teams that are slower than 4RL are not
          recommended for use, since they take too long to give any positive
          output.
        </p>
        <p>The following is a ranking of the speeds,</p>
        <div className="burst-flow just-text">
          <h5>Burst Speeds of Teams Ranked:</h5>
          <p>
            <strong>2RL</strong> (fastest)
          </p>
          <div className="line"></div>
          <p>
            <strong>5SG</strong> (fast)
          </p>
          <p>also known as 2.5RL</p>
          <div className="line"></div>
          <p>
            <strong>3RL</strong>
          </p>
          <div className="line"></div>
          <p>
            <strong>7SG</strong> (slow)
          </p>
          <p>also known as 3.5RL</p>
          <div className="line"></div>
          <p>
            <strong>4RL</strong> (very slow)
          </p>
        </div>
        <p>
          <em>
            Additional information: 2RL≈4SG, 3RL ≈ 6SG shots, 4RL ≈ 8SG shots.
          </em>
        </p>
        <p>
          I believe everyone should know now how the speeds of teams are named,
          which teams are fast and which teams are slow.
        </p>
        <SectionHeader title="Burst Gen Value Charts and Calculations" />
        <p>
          With the following Burst Gen Value Charts, a team's Burst Speed can be
          accurately calculated. The following table does not take into account:
        </p>
        <ul>
          <li>Rocket whiffing/i-framing</li>
          <li>Charge Speed</li>
          <li>Quantum Relic Cube</li>
          <li>Situations where RL only hits 1 or 3 opponents, and</li>
          <li>the situation where cover disappears.</li>
        </ul>
        <p>It assumes the following:</p>
        <ul>
          <li>
            Scarlet has enough ammo to constantly shoot during the time
            duration.
          </li>
          <li>
            Pascal has enough ammo to constantly shoot during the time duration.
          </li>
          <li>
            Anis: Sparkling Summer has a resilience cube and no extra ammo
            through OL gear or Harmony Cubes.
          </li>
        </ul>
        <p>
          <strong>Note:</strong> Due to this, teams with no Max Ammo Overload
          gear stat on Scarlet, non-Wingman cube or no Max ammo Overload gear
          stat on Pascal, or non-reload cube Anis:SS may have lower burst than
          what you calculate using this chart.
        </p>
        <p>
          Jackal, Scarlet, and Harran have a “+” sign in front of her burst gen
          value, since they produce extra burst gen due to their skills.
          Additional Burst gen of Scarlet and Harran is RNG based, while
          Jackal’s is definitive under certain conditions which will be
          explained later. Hence, Jackal’s extra burst gen is of high value and
          the reason why “Jackal feed charts” exist.
        </p>
        <SectionHeader title="How to calculate Burst Speed through charts" />
        <p>
          Select 5 Nikkes. If the sum of the corresponding values is greater
          than 100, then this team will have Burst Speed equal to what is
          mentioned in the title of the Burst Gen Value Chart.
        </p>
        <p>
          TLDR:{' '}
          <strong>
            If sum of values of 5 Nikkes in team &gt; 100, Speed of team = Speed
            mentioned in Chart title.
          </strong>
        </p>
        <SectionHeader title="Effect of Quantum Relic cube on burst gen speed" />
        <p>
          Lvl 1, Lvl 3, Lvl 7 Quantum Cubes have the following set of values
          respectively: 2.33%, 3.5%, 4.66%. Randomly putting this cube is
          useless, but using it to increase a burst Gen Tier is possible. (e.g
          if your Burst Gen Speed in a Tier has a Value of 99.8, using a lvl3
          cube on Laplace will make the Value 100.2, and hence it will upgrade
          Burst Gen Speed Tier).
        </p>
        <p>
          Any Nikke with this cube equipped will have her burst gen increased by
          x% (value of Quantum Cube), according to the burst gen of the Nikke in
          the Value Charts for the respective speeds.
        </p>
        <p>Examples:</p>
        <ul>
          <li>Laplace Burst Gen in 3RL speed: 17.4; in 4RL: 23.2</li>
          <li>
            After lvl3 cube is equipped, Laplace Burst Gen in 3RL speed = 17.4 ×
            102.33% = 17.8
          </li>
          <li>
            After lvl7 cube is equipped, Laplace Burst Gen in 4RL speed = 23.2 ×
            104.66% = 24.2
          </li>
        </ul>
        <SectionHeader title="Burst Speed Charts" />
        <Row xs={1} xl={2} xxl={2} className="charts">
          <Col>
            <div className="box">
              <h5>Lightning fast burst speed Value Chart</h5>
              <p>
                For a team that fully charges when 2 RL shots hit, around 2.5
                seconds to fill gauge.
              </p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_23.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>Very Fast Burst Speed Value Chart</h5>
              <p>For teams that fully charge when 5 SG shots hit (2.5RL).</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_24.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>Fast Burst Speed Value Speed Chart</h5>
              <p>For full gauge in 3 RL shots.</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_25.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>Slow Burst Speed Value Chart</h5>
              <p>For full gauge in 7 SG shots (3.5RL).</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_26.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>Very Slow Speed Value Chart</h5>
              <p>For full gauge in 4 RL shots.</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_27.webp"
                alt="PVP"
              />
            </div>
          </Col>
        </Row>
        <SectionHeader title="Jackal’s Feed Charts" />
        <p>
          This is a section dedicated to Jackal feed, and there's a reason for
          that: Jackal is one of the most meta-defining Nikkes in PvP, due to
          how fast she is by virtue of being a clip RL, but mostly due to her
          skills.
        </p>
        <p>
          Essentially, her skill 1 generates 355 energy (or 3.55% of the burst
          gauge) every time she is hit 10 times, and since she shares damage
          with 2 targets due to skill 2, it makes it so that the enemy is pretty
          much guaranteed to feed Jackal.
        </p>
        <p>
          This heavily cripples any MG not called Rosanna, basically every SMG,
          all SGs and also makes ARs suffer a bit, since she can get extremely
          high feeds from those high fire rate Nikkes. On top of it, pairing her
          with Scarlet means that Scarlet will be pretty much guaranteed to get
          the damage share, so those same high fire rate Nikkes won't only make
          the Jackal team burst extremely fast,
          <strong>
            {' '}
            but they will also take heavy damage from Scarlet's S2
          </strong>{' '}
          (this also applies to other units that proc on hit such as Emma,
          Maiden, Nero, etc but is less relevant to these units as Jackal and
          Scarlet defines the current PvP meta. Also note these self procs such
          as Emma and Maiden{' '}
          <strong>
            do not generate extra burst gauge, but do give other benefits
          </strong>
          ). This makes it so SGs are usable as long as they're not facing a
          Jackal (or Makima, depending on her position), and pretty much any
          weapon type that's not a SR or RL suffers to varying degrees against
          Jackal. If you have Jackal, you're using her in both attack and
          defence, her existence alone restricts what weapons are viable unless
          they have kits designed to counter her.
        </p>
        <p>
          Now that the introduction is out of the way. We need to know how much
          extra energy Jackal provides us with. We will only be looking at 2RL,
          2.5RL and 3RL charts, since if you are using Jackal and still have a
          burst slower than 3RL even after you have added Jackal Feed values,
          you should change that team in our opinion.
        </p>
        <Alert variant="primary">
          <p>
            <em>
              A feed chart has the number of shots each Nikke has made during a
              specific Burst Gen Speed Tier, which allows us to calculate how
              much extra energy jackal gives to a team.
            </em>
          </p>
        </Alert>
        <p>
          To calculate additional Burst Gen provided by Jackal, we use{' '}
          <strong>feed charts</strong>. Feed charts will show the number of
          shots when P1 only is linked with Jackal, and another chart where P1
          and P2 are linked with Jackal.
        </p>
        <p>
          <strong>
            Know which chart to use please. Jackal Feed is usually used to find
            if your team’s Burst Speed Tier is increasing. So if your team is
            currently 3RL, you should find the Jackal Feed values for 2.5RL, and
            add them to what your team’s Burst Gen Speed Value would be for
            2.5RL. If it’s &gt; 100 in 2.5RL, then Jackal made your team faster,
            otherwise this is useless.
          </strong>
        </p>
        <p>
          Pick your chart. Add the values from your feed chart of the 5 Nikkes
          from the opponent team. Divide them by 10. Round down to the nearest
          integer (so 4.4 becomes 4). And multiply this value by 3.55. This will
          be the additional burst gen provided by Jackal.
        </p>
        <p>
          TLDR:{' '}
          <strong>
            Extra Burst gen from Jackal = Sum of the values from Feed charts
            (i.e number of shots) of Nikkes from the opponent team hitting your
            Jackal Linked Nikkes or vice versa. Divide the sum by 10. Round down
            to the nearest integer. Multiply by 3.55.
          </strong>
        </p>
        <h5>Feed charts</h5>
        <p>
          The following are the feed charts for their corresponding speeds. In
          team-building, you want a situation where the opponent Jackal is
          getting low feed, while your Jackal is getting high feed. Thanks to{' '}
          <strong>zixk</strong> for making these charts!
        </p>
        <Row xs={1} xl={2} xxl={2} className="charts">
          <Col>
            <div className="box">
              <h5>2RL feed chart</h5>
              <p>P1 Linked only</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_28.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>2RL feed chart</h5>
              <p>P1+P2 Linked</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_29.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>2.5RL feed chart</h5>
              <p>P1 Linked Only</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_30.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>2.5RL feed chart</h5>
              <p>P1+P2 Linked</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_31.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>3RL Feed Chart</h5>
              <p>P1 Linked Only</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_32.webp"
                alt="PVP"
              />
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>3RL Feed Chart</h5>
              <p>P1+P2 Linked</p>
              <StaticImage
                src="../../../images/nikke/pvp/pvp_33.webp"
                alt="PVP"
              />
            </div>
          </Col>
        </Row>
        <SectionHeader title="Working example for Burst Gen Calculations" />
        <h5>Your team</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noah" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
          <NikkeCharacter mode="icon" slug="centi" enablePopover />
          <NikkeCharacter mode="icon" slug="jackal" enablePopover />
        </div>
        <h5>Enemy team</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter mode="icon" slug="blanc" enablePopover />
          <NikkeCharacter mode="icon" slug="centi" enablePopover />
          <NikkeCharacter mode="icon" slug="anis" enablePopover />
          <NikkeCharacter mode="icon" slug="jackal" enablePopover />
        </div>
        <p>
          <em>
            Assumptions: For both teams, P1 and P2 are linked. Only P1 and P2
            are being hit till full burst. No quantum cubes present. General
            assumption is no target skipping occurs.
          </em>
        </p>
        <Row xs={1} xl={2} xxl={2} className="calc">
          <Col>
            <div className="box">
              <h5>Your team</h5>
              <p>
                (Noah) 18 + (Scarlet) 15.75 + (Biscuit) 16.8 + (Centi) 41.4 +
                (Jackal) 42.6 = <strong>134.55</strong> in 3RL tier calculated
                using 3RL BURST SPEED chart.
              </p>
              <p>
                Normal Burst Gen = 3RL, with 91.35 burst gen in 2.5RL tier
                calculated by using the 2.5RL BURST SPEED chart.
              </p>
              <p>
                Jackal Feed in 2.5RL Tier by opponent team = I will use the
                2.5RL P1+P2 Linked JACKAL FEED chart since we need to find if
                our team’s speed is now faster due to Jackal Feed:
              </p>
              <ul>
                <li>
                  Sum of values: 27 (Scarlet) + 27 (Blanc) + 4 (Centi) + 4
                  (Anis) + 4 (Jackal) = 66
                </li>
                <li>Sum of values/10=6.6, rounding down gives us 6</li>
                <li>6×3.55 = 21.3 extra burst gen.</li>
                <li>
                  2.5RL Burst Speed chart value + Jackal Feed Value = 91.35 +
                  21.3 is greater than 100, so this team is now 2.5RL!
                </li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="box">
              <h5>Enemy team</h5>
              <p>
                12.5(Scarlet) + 5.4(Blanc) + 27.6(Centi) + 28.4(Anis) +
                28.4(Jackal) = <strong>102.3</strong> in 2.5RL tier calculated
                using 2.5RL BURST SPEED chart.
              </p>
              <p>
                Normal Burst gen = 2.5RL, with 98.05 burst gen in 2RL tier
                calculated by using the 2RL BURST SPEED chart.
              </p>
              <p>
                Jackal Feed in 2RL by opponent team = I will use 2RL P1+P2
                linked JACKAL FEED chart because we need to find out if the
                enemy team’s speed tier is now faster due to Jackal’s feed:
              </p>
              <ul>
                <li>
                  Sum of values: 21 (Scarlet) + 4 (Jackal) + Centi (4) + 4
                  (Biscuit) + 4 (Noah) = 37
                </li>
                <li>Sum of values/10=3.7. Rounding Down = 3.</li>
                <li>3×3.55 = 10.65 extra Burst gen by Jackal feed</li>
                <li>
                  2RL Burst Speed Chart Value + Jackal Feed Value = 98.05 +
                  10.65 is greater than 100, hence the team will now Burst in
                  2RL.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Burst Gen Calculator" />
        <p>
          <strong>
            A highly recommended alternative is the Burst Gen Calculator by
            KosMiu.
          </strong>
        </p>
        <p>
          In the calculator, you only need to input the names of the Nikkes in
          each team, and it will give the following information about the team:
        </p>
        <ul>
          <li>Current Burst Gen Speed Tier of the team</li>
          <li>
            Burst Gen Speed Value in each Tier of the team (i.e. the Sum of
            Burst Gen Values of each Nikke in a Speed Tier)
          </li>
          <li>
            Bullets shot in each Tier of the team (i.e. the Sum of all values in
            Jackal Feed charts for each tier), which you just need to divide by
            10, round down and multiply by 3.55 to find the extra Burst gen
            being provided in case Jackal is being used.
          </li>
        </ul>
        <p>
          Hence, these tedious calculations are a thing of the past, just make a
          copy of this amazing calculator and make your lives easier!
        </p>
        <p>
          You can use this calculator to find the Burst Speed of your team. It
          also has Feed chart values automatically summed for you as “Bullets in
          each Tier”! You just need to divide the appropriate number by 10,
          round down and multiply by 3.55, and then add the value to the final
          Burst Gen Value for the same tier! Not only that, it also has Quantum
          Relic Cube calculations automatically added for you! It is honestly a
          life-saver.
        </p>
        <p>
          Make a copy for yourself and read the instructions given on the sheet
          to get started. Notes by the Creator: “For burst generation
          estimations of your team and opponent's team please use the
          spreadsheet below. if the data tables become missing please refresh
          the page and they will repopulate from the source sheet”.
        </p>
        <p>
          <strong>
            Link to Calculator:{' '}
            <a
              href="https://docs.google.com/spreadsheets/d/11KWYeyvef91A-vfFuqglJJ8-OhR90TcKFVpJ6Ny_5h4/edit#gid=1990278246"
              target="_blank"
              rel="noreferrer"
            >
              Burst Gen Calculator
            </a>
          </strong>
        </p>
        <SectionHeader title="Rocket Whiffing" />
        <p>
          When talking about burst gen, it would be criminal to not talk about
          the infamous rocket whiffing.
        </p>
        <p>
          <strong>This is currently all we know about rocket whiffing:</strong>
        </p>
        <p>
          Since Burst Gen is per hit, Rocket whiffs = No Burst Gen since the
          Rocket projectiles completely miss. Rocket launchers, while being
          incredibly powerful, can whiff their bullets on other rocket launchers
          or snipers, this is dependent on 3 known factors:
        </p>
        <ul>
          <li>
            <strong>Factor 1</strong>: The Nikke's charge speed (this can lead
            to permanent rocket whiffing against an opponent),
          </li>
          <li>
            <strong>Factor 2</strong>: The position of the Nikke shooting and
            the position of Nikkes being shot at (refer to image and explanation
            below),
          </li>
          <li>
            <strong>Factor 3</strong>: The projectile speed of the Nikke
            shooting (for example Laplace's projectile travels faster).
          </li>
        </ul>
        <p>
          There is a 4th factor that is currently unknown, suspected to be
          different loading time/network connections to servers which causes
          repeat battles back to back to have varying results. Another possible
          factor is that the Nikke shoots when changing targets (e.g. if the
          Nikke starts to focus on P2 instead of P1 while charging her shot).
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_34.webp" alt="PVP" />
        <p>
          The main problem with tuning speeds is we currently do not possess the
          exact data to formulate breakpoints for causing whiffing in each
          position. and the fact that you cannot check the stats of your
          opponent's Nikkes to know their charge speed modifiers without
          estimating based on fighting against them.{' '}
          <strong>
            Hence, we just leave rocket whiffing to RNG and hope that it never
            happens to us.
          </strong>
        </p>
        <SectionHeader title="Additional Information (for mega nerds only)" />
        <p>
          If anyone wants to know how we reached the values of each Nikke in the
          Burst Gen Speed Value charts, this section is for them. Each value
          corresponds to the percentage of the burst gauge that Nikke has
          filled.
        </p>
        <p>
          This value is calculated by using the Base Burst Gen Value, which we
          found through multiple sources, the major one being Dotgg.gg/Nikke.
          This burst gen value was multiplied by the number of shots at 30fps.
          Then multiplied by the number of targets it is hitting. This gives us
          the answer.
        </p>
        <p>For example, for finding Laplace’s Burst Gen Value for 3RL chart:</p>
        <ul>
          <li>Base Burst Gen Value: 1.45</li>
          <li>Number of shots: 3</li>
          <li>Number of Targets: 4</li>
          <li>
            1.45 × 3 × 4 = 17.4, which is the number you’ll find in the 3RL
            chart.
          </li>
        </ul>
        <p>
          Hence, Laplace will fill 17.4% of the burst gauge after 3RL shots hit.
        </p>
        <p>
          I believe all the major mechanics have now been adequately described.
          This is everything we have found out about PvP till Tia’s banner, and
          I can only hope I did not miss any key points worth knowing. Do
          contact us in the{' '}
          <a
            href="https://discord.com/invite/Gpt2KykNpw"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            Nikke Community Server
          </a>{' '}
          or Prydwen’s very own Discord Server if you want to discuss PvP with
          us!
        </p>
        <p>Now, we will move on to what PvP team-building is all about.</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Basic Team-building & Meta"
            link="/nikke/guides/pvp-sp-arena"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp4.jpg"
                alt="PvP Burst Generation"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkePVPBurst;

export const Head: React.FC = () => (
  <Seo
    title="PvP Burst Generation | NIKKE | Prydwen Institute"
    description="Everything you need to know about Burst Generation in NIKKE PVP."
    game="nikke"
  />
);
